:root {
  --box-shadow: rgba(0, 0, 0, 1);
  --background: #ffffff;
  --color: #000000;
  --header-background: var(--background);
  --focus-color: darkturquoise;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color);
  background: var(--background);
  overflow: hidden;
}

input {
  background: var(--background);
  color: var(--color);
  border-radius: 0.25rem;
  border: 1px solid var(--color);
  height: 1rem;
  padding: 0.25rem 0.5rem;
  width: 6rem;
}

input[type="number"] {
  width: 6ch;
}

input:focus {
  outline: none;
  border-color: var(--focus-color);
}

@media (prefers-color-scheme: dark) {
  :root {
    --box-shadow: hsla(0, 0%, 60%, 1);
    --background: hsla(0, 0%, 10%, 1);
    --color: hsla(0, 0%, 85%, 1);
    --input-background: hsla(0, 0%, 20%, 1);
    --header-background: hsla(0, 0%, 5%, 1);
    --focus-color: orange;
  }
  input {
    background: var(--input-background);
  }

  button {
    background: hsla(0, 0%, 25%, 1);
    border: none;
    border-radius: 0.25rem;
    color: var(--color);
    cursor: pointer;
    padding: 0.5rem 1rem;
    transition: background-color 0.2s;
  }

  button:hover {
    background: hsla(0, 0%, 15%, 1);
    box-shadow: 0px 1px 4px 2px hsla(0, 0%, 50%, 0.5);
  }
}


