.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.row.header > * {
  visibility: hidden;
}

.row > * {
  margin: 0 0.5rem 0.25rem 0;
}

.grid {
  display: grid;
  grid-template-columns: repeat(5, minmax(4ch, 14ch));
  grid-gap: 1rem;
  justify-items: end;
  position: relative;
}

.grid::after {
  content: '';
  position: absolute;
  height: 0.5rem;
  width: 0.5rem;
  right: -0.5rem;
}

.grid > :first-child {
  justify-self: start;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
