.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.App-Wrapper {
  overflow: hidden;
  position: relative;
}
.content {
  overflow: auto;
  flex: 1;
}
.content > * {
  margin: 0.5rem;
}

.footer {
  display: flex;
  justify-content: center;
  padding: 0.25em;
}

.footer > a {
  font-size: 12px;
}