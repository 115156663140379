header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 32px;
  padding: 0.35em 0.5em;
  z-index: 1;
  background: var(--header-background);
  box-shadow: 0 -0.5rem 1rem 0 var(--box-shadow);
}
header > span:not(.total) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.total {
  font-size: 28px;
  padding: 0 0 0 1rem
}