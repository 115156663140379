.error-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: bottom 0.5s ease-out;
  cursor: default;
}

.error-wrapper.visible {
  bottom: 0.5rem;
}

.error-bubble {
  background: lightcoral;
  width: 20rem;
  height: 1rem;
  border-radius: 0.5rem;
  padding: 0.75rem;
  display: flex;
  justify-content: space-between;
  border: 1px solid #990000;
}

.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0.5rem 0 0;
}

.x {
  align-self: center;
  line-height: 1rem;
  font-weight: bold;
  cursor: pointer;
  padding: 0.4rem;
  border-radius: 0.5rem;
  transition: background-color 0.25s;
}

.x:hover {
  background: hsla(0, 0%, 0%, 0.5);
}